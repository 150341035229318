// Action Types
import type { DeleteReservationWorkingDateSettingPayload } from "@/utils/apis/setting/setting.api.types"

export enum SettingActionTypes {
  // Saga
  DELETE_RESERVATION_WORKING_DATE_SETTING_SAGA = "@@setting/DELETE_RESERVATION_WORKING_DATE_SETTING_SAGA",
}

// State

export interface SettingState {}

// ---- Reducer ----

// ---- Saga ----

export type DeleteReservationWorkingDateSettingAction = {
  type: SettingActionTypes.DELETE_RESERVATION_WORKING_DATE_SETTING_SAGA
  payload: DeleteReservationWorkingDateSettingPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type SettingAction =
  //
  DeleteReservationWorkingDateSettingAction
