import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { bookingRemarkApi } from "@/utils/apis"

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action"
import { BookingRemarkActionTypes } from "./types"

import type {
  FetchBookingRemarkSagaAction,
  UpdateBookingRemarkSagaAction,
} from "./types"

function* fetchBookingRemark(action: FetchBookingRemarkSagaAction) {
  const { cancelToken } = action.payload || {}

  yield put(
    fetchRequested({
      scope: "bookingRemark",
    }),
  )

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof bookingRemarkApi.fetchBookingRemark>> =
      yield call(bookingRemarkApi.fetchBookingRemark, {
        cancelToken,
      })

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "bookingRemark",
          data: response.data,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "bookingRemark",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "bookingRemark",
        error: message,
      }),
    )
  }
}

function* updateBookingRemark(action: UpdateBookingRemarkSagaAction) {
  const { params, cancelToken } = action.payload
  const { resolve } = action.meta || {}

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof bookingRemarkApi.updateBookingRemark>> =
      yield call(bookingRemarkApi.updateBookingRemark, {
        params,
        cancelToken,
      })
    resolve && resolve(response)
  } catch (e) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    resolve && resolve({ message })
  }
}

function* bookingRemarkSaga() {
  yield all([
    takeEvery(
      BookingRemarkActionTypes.FETCH_BOOKING_REMARK_SAGA,
      fetchBookingRemark,
    ),
    takeLatest(
      BookingRemarkActionTypes.UPDATE_BOOKING_REMARK_SAGA,
      updateBookingRemark,
    ),
  ])
}

export default bookingRemarkSaga
