import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { userApi } from "@/utils/apis"

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action"
import { DeleteUserAction, UserActionTypes } from "./types"

import type {
  FetchUsersSagaAction,
  FetchUserSagaAction,
  UpdateUserSagaAction,
  CreateUserSagaAction,
} from "./types"

function* fetchUsers(action: FetchUsersSagaAction) {
  const { params, cancelToken } = action.payload

  yield put(
    fetchRequested({
      scope: "users",
    }),
  )

  try {
    const { data: response }: Awaited<ReturnType<typeof userApi.fetchUsers>> =
      yield call(userApi.fetchUsers, {
        params,
        cancelToken,
      })

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "users",
          data: response.data.list,
          count: response.data.count,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "users",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "users",
        error: message,
      }),
    )
  }
}

function* fetchUser(action: FetchUserSagaAction) {
  const { params, cancelToken } = action.payload

  yield put(
    fetchRequested({
      scope: "user",
    }),
  )

  try {
    const { data: response }: Awaited<ReturnType<typeof userApi.fetchUser>> =
      yield call(userApi.fetchUser, {
        params,
        cancelToken,
      })

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "user",
          data: response.data,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "user",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "user",
        error: message,
      }),
    )
  }
}

function* createUser(action: CreateUserSagaAction) {
  const { params, cancelToken } = action.payload
  const { resolve } = action.meta || {}

  try {
    const { data: response }: Awaited<ReturnType<typeof userApi.createUser>> =
      yield call(userApi.createUser, {
        params,
        cancelToken,
      })
    resolve && resolve(response)
  } catch (e) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    resolve && resolve({ message })
  }
}

function* updateUser(action: UpdateUserSagaAction) {
  const { params, cancelToken } = action.payload
  const { resolve } = action.meta || {}
  try {
    const { data: response }: Awaited<ReturnType<typeof userApi.updateUser>> =
      yield call(userApi.updateUser, {
        params,
        cancelToken,
      })
    resolve && resolve(response)
  } catch (e) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    resolve && resolve({ message })
  }
}

function* deleteUser(action: DeleteUserAction) {
  const { params, cancelToken } = action.payload
  const { resolve } = action.meta || {}

  try {
    const { data: response }: Awaited<ReturnType<typeof userApi.deleteUser>> =
      yield call(userApi.deleteUser, {
        params,
        cancelToken,
      })
    resolve && resolve(response)
  } catch (e) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    resolve && resolve({ message })
  }
}

function* resetUserPassword(action: DeleteUserAction) {
  const { params, cancelToken } = action.payload
  const { resolve } = action.meta || {}

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof userApi.resetUserPassword>> = yield call(
      userApi.resetUserPassword,
      {
        params,
        cancelToken,
      },
    )
    resolve && resolve(response)
  } catch (e) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    resolve && resolve({ message })
  }
}

function* userSaga() {
  yield all([
    takeEvery(UserActionTypes.FETCH_USERS_SAGA, fetchUsers),
    takeEvery(UserActionTypes.FETCH_USER_SAGA, fetchUser),
    takeLatest(UserActionTypes.CREATE_USER_SAGA, createUser),
    takeLatest(UserActionTypes.UPDATE_USER_SAGA, updateUser),
    takeLatest(UserActionTypes.DELETE_USER_SAGA, deleteUser),
    takeLatest(UserActionTypes.RESET_USER_PASSWORD_SAGA, resetUserPassword),
  ])
}

export default userSaga
