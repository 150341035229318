// Action Types
import type {
  FetchUserLogsPayload,
  FetchUserLogsResponseData,
} from "@/utils/apis/userLog/userLog.api.types"

export enum UserLogActionTypes {
  FETCH_REQUESTED = "@@userLog/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@userLog/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@userLog/FETCH_FAILED",

  // Saga
  FETCH_USER_LOGS_SAGA = "@@userLog/FETCH_USER_LOGS_SAGA",
}

// State

export interface UserLogState {
  userLogs: FetchUserLogsResponseData[]
  userLogsCount: number
  userLogsLoading: boolean
  userLogsError: string
}

// ---- Reducer ----

export type FetchRequestedAction = {
  type: UserLogActionTypes.FETCH_REQUESTED
  payload: {
    scope: "userLogs"
  }
}

export type FetchSucceededAction = {
  type: UserLogActionTypes.FETCH_SUCCEEDED
  payload: {
    scope: "userLogs"
    data: UserLogState[FetchSucceededAction["payload"]["scope"]]
    count?: number
  }
}

export type FetchFailedAction = {
  type: UserLogActionTypes.FETCH_FAILED
  payload: {
    scope: "userLogs"
    error: string
  }
}

// ---- Saga ----

export type FetchUserLogsSagaAction = {
  type: UserLogActionTypes.FETCH_USER_LOGS_SAGA
  payload: FetchUserLogsPayload
}

export type UserLogAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchUserLogsSagaAction
