import {
  DeleteReservationWorkingDateSettingAction,
  SettingActionTypes,
} from "./types"
// import type {
//   // Saga
//   SettingState,
// } from "./types"

// ---- REDUCER ----

// ---- SAGA ----

export const deleteReservationWorkingDateSettingSaga = (
  payload: DeleteReservationWorkingDateSettingAction["payload"],
  meta: DeleteReservationWorkingDateSettingAction["meta"],
): DeleteReservationWorkingDateSettingAction => ({
  type: SettingActionTypes.DELETE_RESERVATION_WORKING_DATE_SETTING_SAGA,
  payload,
  meta,
})
