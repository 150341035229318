import { createTheme } from "@mui/material/styles"
import { TypographyOptions } from "@mui/material/styles/createTypography"

import generalStyles from "./general.styles"
import overridesStyles from "./overrides.styles"
import customStyles from "./custom.styles"

const muiTheme = createTheme()

const typography: TypographyOptions = {
  fontFamily: '"Roboto",sans-serif',
  fontWeightBold: 700,
  fontWeightSemiBold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  fontWeightLight: 300,
}

typography.button = {
  fontFamily: '"Roboto",sans-serif',
  // fontWeight: 500,
  fontStyle: "normal",
  // fontSize: 16,
  textTransform: "initial",
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#FD4A4E",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParams: any) => `
				${generalStyles(themeParams)}
				${overridesStyles(themeParams)}
        ${customStyles(themeParams)}
			`,
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 1350,
        },
      },
    },
  },
  typography: typography,
  zIndex: {
    backdrop: 1350,
  },
  app: {},
})

export default defaultTheme
