import type { FetchCmsPermissionPayload } from "./cmsPermission.api.types"
import { authAxios, AxiosResponseData } from "@/utils/axios"

const cmsPermissionApi = {
  fetchCmsPermissions: (payload: FetchCmsPermissionPayload) => {
    return authAxios.get<AxiosResponseData>("cms-permissions", {
      cancelToken: payload.cancelToken,
      params: payload.params,
    })
  },
}

export default cmsPermissionApi
