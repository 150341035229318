import { ShopActionTypes } from "./types"
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchShopsSagaAction,
} from "./types"

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"],
): FetchRequestedAction => ({
  type: ShopActionTypes.FETCH_REQUESTED,
  payload,
})

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"],
): FetchSucceededAction => ({
  type: ShopActionTypes.FETCH_SUCCEEDED,
  payload,
})

export const fetchFailed = (
  payload: FetchFailedAction["payload"],
): FetchFailedAction => ({
  type: ShopActionTypes.FETCH_FAILED,
  payload,
})

// ---- SAGA ----

export const fetchAllShopsSaga = (
  payload: FetchShopsSagaAction["payload"],
): FetchShopsSagaAction => ({
  type: ShopActionTypes.FETCH_ALL_SHOPS_SAGA,
  payload,
})
