import type { FetchUserLogsPayload } from "./userLog.api.types"
import { authAxios, AxiosResponseData } from "@/utils/axios"

const userLogApi = {
  fetchUserLogs: (payload: FetchUserLogsPayload) => {
    return authAxios.get<AxiosResponseData>("user-logs", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    })
  },
}

export default userLogApi
