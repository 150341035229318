// Action Types
import type {
  FetchAppVersionsPayload,
  FetchAppVersionPayload,
  UpdateAppVersionPayload,
} from "@/utils/apis/appVersion/appVersion.api.types"

export enum AppVersionActionTypes {
  FETCH_REQUESTED = "@@appVersion/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@appVersion/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@appVersion/FETCH_FAILED",

  // Saga
  FETCH_APP_VERSIONS_SAGA = "@@appVersion/FETCH_APP_VERSIONS_SAGA",
  FETCH_APP_VERSION_SAGA = "@@appVersion/FETCH_APP_VERSION_SAGA",
  UPDATE_APP_VERSION_SAGA = "@@appVersion/UPDATE_APP_VERSION_SAGA",
}

// State

export interface AppVersions {
  id: number
  updatedAt: string
  updatedByUser: {
    id: number
    firstName: string
  } | null
  flatform: string
  version: string
  url: string
}

export interface AppVersion extends AppVersions {}

export interface AppVersionState {
  appVersions: AppVersions[]
  appVersionsCount: number
  appVersionsLoading: boolean
  appVersionsError: string

  appVersion: AppVersion | null
  appVersionLoading: boolean
  appVersionError: string
}

// ---- Reducer ----

export type FetchRequestedAction = {
  type: AppVersionActionTypes.FETCH_REQUESTED
  payload: {
    scope: "appVersion" | "appVersions"
  }
}

export type FetchSucceededAction = {
  type: AppVersionActionTypes.FETCH_SUCCEEDED
  payload: {
    scope: "appVersion" | "appVersions"
    data: AppVersionState[FetchSucceededAction["payload"]["scope"]]
    count?: number
  }
}

export type FetchFailedAction = {
  type: AppVersionActionTypes.FETCH_FAILED
  payload: {
    scope: "appVersion" | "appVersions"
    error: string
  }
}

// ---- Saga ----

export type FetchAppVersionsSagaAction = {
  type: AppVersionActionTypes.FETCH_APP_VERSIONS_SAGA
  payload: FetchAppVersionsPayload
}

export type FetchAppVersionSagaAction = {
  type: AppVersionActionTypes.FETCH_APP_VERSION_SAGA
  payload: FetchAppVersionPayload
}

export type UpdateAppVersionSagaAction = {
  type: AppVersionActionTypes.UPDATE_APP_VERSION_SAGA
  payload: UpdateAppVersionPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type AppVersionAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchAppVersionsSagaAction
  | FetchAppVersionSagaAction
  | UpdateAppVersionSagaAction
