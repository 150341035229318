import type {
  FetchAppVersionsPayload,
  FetchAppVersionPayload,
  UpdateAppVersionPayload,
} from "./appVersion.api.types"
import { authAxios, AxiosResponseData } from "@/utils/axios"

const appVersionApi = {
  fetchAppVersions: (payload: FetchAppVersionsPayload) => {
    return authAxios.get<AxiosResponseData>("app-version", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
    })
  },
  fetchAppVersion: (payload: FetchAppVersionPayload) => {
    return authAxios.get<AxiosResponseData>(
      `app-version/${payload?.params?.id}`,
    )
  },
  updateAppVersion: (payload: UpdateAppVersionPayload) => {
    const { id, ...params } = payload?.params
    return authAxios.put<AxiosResponseData>(`app-version/${id}`, params, {
      cancelToken: payload?.cancelToken,
    })
  },
}

export default appVersionApi
