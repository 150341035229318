export const allocateRoute = (status: any) => {
  switch (status) {
    case "": {
    }
  }
}

export const checkRequestSuccess = (response: any) => {
  return response?.status
}

export const checkRequestInvalidToken = (response: any) => {
  return response?.status
}
