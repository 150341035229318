// Action Types
import type {
  FetchReservationHistoriesPayload,
  FetchReservationHistoriesResponseData,
  FetchReservationHistoryChartDataPayload,
  FetchReservationHistoryChartDataResponseData,
  FetchReservationHistoryPayload,
  FetchReservationHistoryResponseData,
  RefundReservationPayload,
  RefundReservationResponseData,
} from "@/utils/apis/reservationHistory/reservationHistory.api.types"

export enum ReservationHistoryActionTypes {
  FETCH_REQUESTED = "@@reservationHistory/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@reservationHistory/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@reservationHistory/FETCH_FAILED",

  MAP_FETCH_REQUESTED = "@@reservationHistory/MAP_FETCH_REQUESTED",
  MAP_FETCH_SUCCEEDED = "@@reservationHistory/MAP_FETCH_SUCCEEDED",
  MAP_FETCH_FAILED = "@@reservationHistory/MAP_FETCH_FAILED",

  REFUND_RESERVATION_SUCCEEDED = "@@reservationHistory/REFUND_RESERVATION_SUCCEEDED",

  UPDATE_RESERVATION = "@@reservationHistory/UPDATE_RESERVATION",

  // Saga
  FETCH_RESERVATION_HISTORIES_SAGA = "@@reservationHistory/FETCH_RESERVATION_HISTORIES_SAGA",
  FETCH_RESERVATION_HISTORY_SAGA = "@@reservationHistory/FETCH_RESERVATION_HISTORY_SAGA",
  FETCH_RESERVATION_HISTORY_CHART_DATA_SAGA = "@@reservationHistory/FETCH_RESERVATION_HISTORY_CHART_DATA_SAGA",
  REFUND_RESERVATION_SAGA = "@@reservationHistory/REFUND_RESERVATION_SAGA",
}

// State

export interface ReservationHistoryState {
  reservationHistories: FetchReservationHistoriesResponseData["list"]
  reservationHistoriesLoading: boolean
  reservationHistoriesError: string
  reservationHistoriesCount: number

  idToReservationHistoryMap: {
    [id: string | number]: FetchReservationHistoryResponseData
  }
  idToReservationHistoryLoadingMap: {
    [id: string | number]: boolean
  }
  idToReservationHistoryErrorMap: {
    [id: string | number]: string
  }

  reservationHistoryChartData: FetchReservationHistoryChartDataResponseData
  reservationHistoryChartDataLoading: boolean
  reservationHistoryChartDataError: string
}

// ---- Reducer ----

type FetchActionScope = "reservationHistories" | "reservationHistoryChartData"

type MapFetchActionScope = "idToReservationHistory"

export type FetchRequestedAction = {
  type: ReservationHistoryActionTypes.FETCH_REQUESTED
  payload: {
    scope: FetchActionScope
  }
}

export type FetchSucceededAction = {
  type: ReservationHistoryActionTypes.FETCH_SUCCEEDED
  payload: {
    scope: FetchActionScope
    data: ReservationHistoryState[FetchActionScope]
    count?: number
  }
}

export type FetchFailedAction = {
  type: ReservationHistoryActionTypes.FETCH_FAILED
  payload: {
    scope: FetchActionScope
    error: string
  }
}

export type MapFetchRequestedAction = {
  type: ReservationHistoryActionTypes.MAP_FETCH_REQUESTED
  payload: {
    scope: MapFetchActionScope
    key: string | number
  }
}

export type MapFetchSucceededAction = {
  type: ReservationHistoryActionTypes.MAP_FETCH_SUCCEEDED
  payload: {
    scope: MapFetchActionScope
    key: string | number
    data: ReservationHistoryState[`${MapFetchActionScope}Map`][MapFetchSucceededAction["payload"]["key"]]
  }
}

export type MapFetchFailedAction = {
  type: ReservationHistoryActionTypes.MAP_FETCH_FAILED
  payload: {
    scope: MapFetchActionScope
    key: string | number
    error: string
  }
}

export type RefundReservationSucceeded = {
  type: ReservationHistoryActionTypes.REFUND_RESERVATION_SUCCEEDED
  payload: RefundReservationResponseData
  meta: {
    scope: "reservationHistories" | "idToReservationHistoryMap"
  }
}

export type UpdateReservation = {
  type: ReservationHistoryActionTypes.UPDATE_RESERVATION
  payload: Partial<
    Pick<
      FetchReservationHistoryResponseData,
      | "id"
      | "beginRefundDate"
      | "completeRefundDate"
      | "endRefundDate"
      | "isCompleteRefund"
      | "refundAmount"
      | "refundReason"
      | "isActive"
      | "booking"
      | "deposits"
    >
  > & {
    id: number
  }
  meta: {
    scope: "reservationHistories" | "idToReservationHistoryMap"
  }
}

// ---- Saga ----

export type FetchReservationHistoriesSagaAction = {
  type: ReservationHistoryActionTypes.FETCH_RESERVATION_HISTORIES_SAGA
  payload?: FetchReservationHistoriesPayload
  meta?: {
    disabledLoading?: boolean
    resolve?: (payload?: any) => void
  }
}

export type FetchReservationHistoryChartDataSagaAction = {
  type: ReservationHistoryActionTypes.FETCH_RESERVATION_HISTORY_CHART_DATA_SAGA
  payload?: FetchReservationHistoryChartDataPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type FetchReservationHistorySagaAction = {
  type: ReservationHistoryActionTypes.FETCH_RESERVATION_HISTORY_SAGA
  payload: FetchReservationHistoryPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type RefundReservationSagaAction = {
  type: ReservationHistoryActionTypes.REFUND_RESERVATION_SAGA
  payload: RefundReservationPayload
  meta: {
    scope: "reservationHistories" | "idToReservationHistoryMap"
    resolve?: (payload?: any) => void
  }
}

export type ReservationHistoryAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MapFetchRequestedAction
  | MapFetchSucceededAction
  | MapFetchFailedAction
  | RefundReservationSucceeded
  | UpdateReservation
  //
  | FetchReservationHistoriesSagaAction
  | FetchReservationHistorySagaAction
  | FetchReservationHistoryChartDataSagaAction
  | RefundReservationSagaAction
