// Action Types
import type {
  FetchBookingRemarkPayload,
  UpdateBookingRemarkPayload,
} from "@/utils/apis/bookingRemark/bookingRemark.api.types"

export enum BookingRemarkActionTypes {
  FETCH_REQUESTED = "@@bookingRemark/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@bookingRemark/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@bookingRemark/FETCH_FAILED",

  // Saga
  FETCH_BOOKING_REMARK_SAGA = "@@bookingRemark/FETCH_BOOKING_REMARK_SAGA",
  UPDATE_BOOKING_REMARK_SAGA = "@@bookingRemark/UPDATE_BOOKING_REMARK_SAGA",
}

// State

export interface BookingRemark {
  name: string
  key: string
  enumSettingLanguages: {
    alias: "en_us" | "zh_hk" | "zh_cn"
    value: string[]
  }[]
}

export interface BookingRemarkState {
  bookingRemark: BookingRemark | null
  bookingRemarkLoading: boolean
  bookingRemarkError: string
}

// ---- Reducer ----

export type FetchRequestedAction = {
  type: BookingRemarkActionTypes.FETCH_REQUESTED
  payload: {
    scope: "bookingRemark"
  }
}

export type FetchSucceededAction = {
  type: BookingRemarkActionTypes.FETCH_SUCCEEDED
  payload: {
    scope: "bookingRemark"
    data: BookingRemarkState[FetchSucceededAction["payload"]["scope"]]
    count?: number
  }
}

export type FetchFailedAction = {
  type: BookingRemarkActionTypes.FETCH_FAILED
  payload: {
    scope: "bookingRemark"
    error: string
  }
}

// ---- Saga ----

export type FetchBookingRemarkSagaAction = {
  type: BookingRemarkActionTypes.FETCH_BOOKING_REMARK_SAGA
  payload?: FetchBookingRemarkPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type UpdateBookingRemarkSagaAction = {
  type: BookingRemarkActionTypes.UPDATE_BOOKING_REMARK_SAGA
  payload: UpdateBookingRemarkPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type BookingRemarkAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchBookingRemarkSagaAction
  | UpdateBookingRemarkSagaAction
