import { CustomerActionTypes } from "./types"
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchCustomerSagaAction,
  FetchCustomersSagaAction,
} from "./types"

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"],
): FetchRequestedAction => ({
  type: CustomerActionTypes.FETCH_REQUESTED,
  payload,
})

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"],
): FetchSucceededAction => ({
  type: CustomerActionTypes.FETCH_SUCCEEDED,
  payload,
})

export const fetchFailed = (
  payload: FetchFailedAction["payload"],
): FetchFailedAction => ({
  type: CustomerActionTypes.FETCH_FAILED,
  payload,
})

// ---- SAGA ----

export const fetchCustomersSaga = (
  payload: FetchCustomersSagaAction["payload"],
): FetchCustomersSagaAction => ({
  type: CustomerActionTypes.FETCH_CUSTOMERS_SAGA,
  payload,
})

export const fetchCustomerSaga = (
  payload: FetchCustomerSagaAction["payload"],
): FetchCustomerSagaAction => ({
  type: CustomerActionTypes.FETCH_CUSTOMER_SAGA,
  payload,
})
