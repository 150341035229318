import { BookingRemarkActionTypes } from "./types"
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchBookingRemarkSagaAction,
  UpdateBookingRemarkSagaAction,
} from "./types"

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"],
): FetchRequestedAction => ({
  type: BookingRemarkActionTypes.FETCH_REQUESTED,
  payload,
})

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"],
): FetchSucceededAction => ({
  type: BookingRemarkActionTypes.FETCH_SUCCEEDED,
  payload,
})

export const fetchFailed = (
  payload: FetchFailedAction["payload"],
): FetchFailedAction => ({
  type: BookingRemarkActionTypes.FETCH_FAILED,
  payload,
})

// ---- SAGA ----

export const fetchBookingRemarkSaga = (
  payload?: FetchBookingRemarkSagaAction["payload"],
): FetchBookingRemarkSagaAction => ({
  type: BookingRemarkActionTypes.FETCH_BOOKING_REMARK_SAGA,
  payload,
})

export const updateBookingRemarkSaga = (
  payload: UpdateBookingRemarkSagaAction["payload"],
  meta?: UpdateBookingRemarkSagaAction["meta"],
): UpdateBookingRemarkSagaAction => ({
  type: BookingRemarkActionTypes.UPDATE_BOOKING_REMARK_SAGA,
  payload,
  meta,
})
