import { UserLogActionTypes } from "./types"
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchUserLogsSagaAction,
} from "./types"

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"],
): FetchRequestedAction => ({
  type: UserLogActionTypes.FETCH_REQUESTED,
  payload,
})

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"],
): FetchSucceededAction => ({
  type: UserLogActionTypes.FETCH_SUCCEEDED,
  payload,
})

export const fetchFailed = (
  payload: FetchFailedAction["payload"],
): FetchFailedAction => ({
  type: UserLogActionTypes.FETCH_FAILED,
  payload,
})

// ---- SAGA ----

export const fetchUserLogsSaga = (
  payload: FetchUserLogsSagaAction["payload"],
): FetchUserLogsSagaAction => ({
  type: UserLogActionTypes.FETCH_USER_LOGS_SAGA,
  payload,
})
