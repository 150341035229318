import axios from "axios"

import { commonConfig } from "../config"

const commonAxios = axios.create({
  baseURL: `${commonConfig.API_HOST}api/v1/`,
})

commonAxios.interceptors.request.use(
  req => {
    switch ((req.method as string).toUpperCase()) {
      case "GET": {
        req.params = req.params || {}
        Object.assign(req.params, {
          language: window.NextPublic.lang.toLowerCase(),
        })
        break
      }
      case "POST": {
        if (req.data instanceof FormData) {
          req.data.append("language", window.NextPublic.lang.toLowerCase())
        } else {
          req.data = req.data || {}
          Object.assign(req.data, {
            language: window.NextPublic.lang.toLowerCase(),
          })
        }
        break
      }
      case "PUT": {
        if (req.data instanceof FormData) {
          req.data.append("language", window.NextPublic.lang.toLowerCase())
        } else {
          req.data = req.data || {}
          Object.assign(req.data, {
            language: window.NextPublic.lang.toLowerCase(),
          })
        }
        break
      }
    }
    return req
  },
  err => {
    console.log(err)
    return Promise.reject(err)
  },
)

commonAxios.interceptors.response.use(
  res => {
    // if (!["", null, undefined].includes(res?.data?.error_code)) {
    // 	// helpers.axios.allocateRoute(res.data.error_code)
    // }

    return res
  },
  err => {
    console.log(err)
    return Promise.reject(err)
  },
)

export default commonAxios
