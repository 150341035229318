import { useEffect, useRef, useState } from "react"
import useCounter from "./useCounter"
import useEventCallback from "./useEventCallback"

const useCountDown = () => {
  const { count, decrement, setCount, reset: rs } = useCounter(0)

  const countDownStoppedRef = useRef(false)

  const start = (countStart: number) => {
    countDownStoppedRef.current = false
    if (count < 1 && countStart > 0) {
      setCount(countStart)
      return
    }
  }

  const stop = () => {
    countDownStoppedRef.current = true
  }

  const reset = () => {
    setCount(0)
  }

  const executeDecrement = useEventCallback(() => {
    if (count < 1) return
    decrement()
  })

  useEffect(() => {
    let countDownTimeout: ReturnType<typeof setTimeout>
    const decrementCountDown = () => {
      if (!countDownStoppedRef.current) {
        executeDecrement()
      }
      countDownTimeout = setTimeout(() => {
        decrementCountDown()
      }, 1000)
    }
    decrementCountDown()
    return () => {
      clearTimeout(countDownTimeout)
    }
  }, [])

  return {
    count,
    start,
    stop,
    reset,
  }
}

export default useCountDown
