import useAppSelector from "./useAppSelector"

export type PermissionName =
  | "CREATE_USER"
  | "UPDATE_USER"
  | "LIST_USER"
  | "RESERVATION_NOTICE_QUICK_CHANGE"
  | "RESERVATION_OPENING_QUICK_CHANGE"
  | "RESERVATION_DISABLE_QUICK_CHANGE"
  | "RESERVATION_SETTING_QUERY"
  | "BOOKING_APP_VERSION_CONTROL"
  | "BRANCH_BOOKING_HISTORY"
  | "DASHBOARD_CHART"
  | "BACKEND_OPERATION_HISTORY"
  | "BOOKING_REMARK"
  | "RESERVATION_OVERDUE_QUICK_CHANGE"
  | "DASHBOARD_CHART"
  | "CUSTOMERS"
  | "BACKEND_OPERATION_HISTORY"
  | "BRANCH_BOOKING_HISTORY"
  | "RESERVATION_HISTORY"
  | "RESERVATION_BEFORE_TIME_QUICK_CHANGE"
  | "RESERVATION_BABY_SEAT_QUICK_CHANGE"
  | "RESERVATION_CALENDAR_DIVISION_TIME_QUICK_CHANGE"

const useCmsPermission = () => {
  const $s_userPermissions = useAppSelector(
    state => state.auth.user?.permissions || [],
  )
  const $s_canAccessAllPlatforms = useAppSelector(
    state => !!state.auth.user?.isSupperAdmin,
  )

  const $s_userAuthLoading = useAppSelector(
    state => !!state.auth.userAuthChecking,
  )

  const canAccess = (name: PermissionName | PermissionName[]) => {
    // if (Array.isArray(name))
    //   return name.every(permissionName =>
    //     $s_userPermissions.some(
    //       userPermission => userPermission.name === permissionName,
    //     ),
    //   )
    return (
      $s_canAccessAllPlatforms ||
      $s_userPermissions.some(userPermission =>
        Array.isArray(name)
          ? name.includes(userPermission.name as any)
          : userPermission.name === name,
      )
    )
  }

  return {
    canAccess,
    isLoading: $s_userAuthLoading,
  }
}

export default useCmsPermission
