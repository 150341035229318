import { authAxios, AxiosResponseData } from "@/utils/axios"
import { FetchAllShopsPayload } from "./shop.api.types"

const shopApi = {
  fetchAllShops: (payload: FetchAllShopsPayload) => {
    return authAxios.get<AxiosResponseData>("branches", {
      cancelToken: payload.cancelToken,
    })
  },
}

export default shopApi
