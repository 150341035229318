import axios from "axios"
import { jwtService } from "@/services"
import { axiosHelpers } from "@/utils/helpers"
import { eventBusService } from "@/services"
import { eventBusCommonConstants } from "@/utils/constants"
import { commonConfig } from "../config"

const authAxios = axios.create({
  baseURL: `${commonConfig.API_HOST}api/v1/`,
})

authAxios.interceptors.request.use(
  req => {
    const token = jwtService.getToken() || undefined

    req.headers!["Authorization"] = `bearer ${token}`

    switch ((req.method as string).toUpperCase()) {
      case "GET": {
        req.params = req.params || {}
        Object.assign(req.params, {
          language: window.NextPublic.lang.toLowerCase(),
        })
        break
      }
      case "POST": {
        if (req.data instanceof FormData) {
          req.data.append("language", window.NextPublic.lang.toLowerCase())
        } else {
          req.data = req.data || {}
          Object.assign(req.data, {
            language: window.NextPublic.lang.toLowerCase(),
          })
        }
        break
      }
      case "PUT": {
        if (req.data instanceof FormData) {
          req.data.append("language", window.NextPublic.lang.toLowerCase())
        } else {
          req.data = req.data || {}
          Object.assign(req.data, {
            language: window.NextPublic.lang.toLowerCase(),
          })
        }
        break
      }
    }
    return req
  },
  err => {
    console.log(err.response)
    return Promise.reject(err)
  },
)

authAxios.interceptors.response.use(
  res => {
    if (axiosHelpers.checkRequestInvalidToken(res.data)) {
      // eventBusService.dispatch(eventBusCommonConstants.AUTH_EXPIRED_TOKEN)
    }
    return res
  },
  err => {
    return Promise.reject(err)
  },
)

export default authAxios
