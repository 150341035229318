import type { FetchReservationHistoryResponseData } from "@/utils/apis/reservationHistory/reservationHistory.api.types"

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export const canRefundReservation = (
  reservationHistory: DeepPartial<FetchReservationHistoryResponseData>,
) => {
  return (
    !reservationHistory.isCompleteRefund &&
    (reservationHistory.isActive ||
      !!reservationHistory.booking?.isOnlineBooking) &&
    ["confirmed", "paid"].includes(
      (reservationHistory.deposits?.[0]?.statusCode || "").toLowerCase(),
    )
  )
}
