import commonAxios from "@/utils/axios/common.axios"

import type { SignInPayload, ChangePasswordPayload } from "./auth.api.types"
import { authAxios, AxiosResponseData } from "@/utils/axios"

const authApi = {
  signIn: (payload: SignInPayload) => {
    return commonAxios.post<AxiosResponseData>("auth/login", payload.params)
  },
  fetchUser: () => {
    return authAxios.get<AxiosResponseData>("auth/current-user")
  },
  changePassword: (payload: ChangePasswordPayload) => {
    const { id, ...restParams } = payload.params
    return authAxios.put<AxiosResponseData>(`users/${id}/password`, restParams)
  },
}

export default authApi
