import { createContext } from "react";

import type { Socket } from "socket.io-client";

export type SocketIOContextProps = {
  socket: Socket;
};

const SocketIOContext = createContext<SocketIOContextProps>(undefined!);

export default SocketIOContext;
