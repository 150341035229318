// Action Types
import type {
  FetchUsersPayload,
  FetchUserPayload,
  CreateUserPayload,
  UpdateUserPayload,
  ResetUserPasswordPayload,
  DeleteUserPayload,
} from "@/utils/apis/user/user.api.types"

export enum UserActionTypes {
  FETCH_REQUESTED = "@@user/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@user/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@user/FETCH_FAILED",
  SET_USERS_FIELD_VALUES = "@@user/SET_USERS_FIELD_VALUES",

  // Saga
  FETCH_USERS_SAGA = "@@user/FETCH_USERS_SAGA",
  FETCH_USER_SAGA = "@@user/FETCH_USER_SAGA",
  CREATE_USER_SAGA = "@@user/CREATE_USER_SAGA",
  UPDATE_USER_SAGA = "@@user/UPDATE_USER_SAGA",
  RESET_USER_PASSWORD_SAGA = "@@user/RESET_USER_PASSWORD_SAGA",
  DELETE_USER_SAGA = "@@user/DELETE_USER_SAGA",
}

// State

export interface User {
  id: number
  email: string
  isAdmin: boolean
  isSupperAdmin: boolean
  shops: {
    id: number
    name: string
  }[]
  isCmsUser: boolean
}

export interface UserState {
  users: User[]
  usersCount: number
  usersLoading: boolean
  usersError: string

  user:
    | (User & {
        permissions: {
          id: number
          name: string
          description: string
        }[]
      })
    | null
  userLoading: boolean
  userError: string
}

// ---- Reducer ----

export type FetchRequestedAction = {
  type: UserActionTypes.FETCH_REQUESTED
  payload: {
    scope: "user" | "users"
  }
}

export type FetchSucceededAction = {
  type: UserActionTypes.FETCH_SUCCEEDED
  payload: {
    scope: "user" | "users"
    data: UserState[FetchSucceededAction["payload"]["scope"]]
    count?: number
  }
}

export type FetchFailedAction = {
  type: UserActionTypes.FETCH_FAILED
  payload: {
    scope: "user" | "users"
    error: string
  }
}

export type SetUsersFieldValues = {
  type: UserActionTypes.SET_USERS_FIELD_VALUES
  payload: {
    index: number
    data: User
  }
}

// ---- Saga ----

export type FetchUsersSagaAction = {
  type: UserActionTypes.FETCH_USERS_SAGA
  payload: FetchUsersPayload
}

export type FetchUserSagaAction = {
  type: UserActionTypes.FETCH_USER_SAGA
  payload: FetchUserPayload
}

export type CreateUserSagaAction = {
  type: UserActionTypes.CREATE_USER_SAGA
  payload: CreateUserPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type UpdateUserSagaAction = {
  type: UserActionTypes.UPDATE_USER_SAGA
  payload: UpdateUserPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type ResetUserPasswordAction = {
  type: UserActionTypes.RESET_USER_PASSWORD_SAGA
  payload: ResetUserPasswordPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type DeleteUserAction = {
  type: UserActionTypes.DELETE_USER_SAGA
  payload: DeleteUserPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type UserAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | SetUsersFieldValues
  //
  | FetchUsersSagaAction
  | FetchUserSagaAction
  | CreateUserSagaAction
  | UpdateUserSagaAction
  | ResetUserPasswordAction
  | DeleteUserAction
