import { all, call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { shopApi } from "@/utils/apis"

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action"
import { ShopActionTypes } from "./types"

import type { FetchShopsSagaAction } from "./types"

function* fetchAllShops(action: FetchShopsSagaAction) {
  const { cancelToken } = action.payload

  yield put(
    fetchRequested({
      scope: "shops",
    }),
  )

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof shopApi.fetchAllShops>> = yield call(
      shopApi.fetchAllShops,
      {
        cancelToken,
      },
    )

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "shops",
          data: response.data.branches,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "shops",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "shops",
        error: message,
      }),
    )
  }
}

function* shopSaga() {
  yield all([takeEvery(ShopActionTypes.FETCH_ALL_SHOPS_SAGA, fetchAllShops)])
}

export default shopSaga
