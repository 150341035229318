import { AppVersionActionTypes } from "./types"
import type { AppVersionState, AppVersionAction } from "./types"
import { commonHelpers } from "@/utils/helpers"

export const initialState: AppVersionState = {
  appVersions: [],
  appVersionsCount: 0,
  appVersionsError: "",
  appVersionsLoading: false,

  appVersion: null,
  appVersionError: "",
  appVersionLoading: false,
}

const reducer = (state = initialState, action: AppVersionAction) => {
  switch (action.type) {
    case AppVersionActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      }
    }
    case AppVersionActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        [`${scope}Error`]: "",
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      }
    }

    case AppVersionActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload

      return {
        ...state,
        [`${scope}Error`]: error,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
