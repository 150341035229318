import { useEffect } from "react";
import _isEqual from "lodash/isEqual";

import { commonHelpers } from "@/utils/helpers";

import useIsMounted from "./useIsMounted";
import useDeepCompareEffect from "./useDeepCompareEffect";

type CacheTime = "infinity" | number;

type UseReduxQueryCacheOptions<D, P extends Object> = {
  cacheTime?: CacheTime;
  data?: D;
  params?: P;
  loading?: boolean;
  disabled?: boolean;
};

const cache = new Map<
  string,
  {
    timeOnRefetch?: number;
    params?: Object;
  }
>();

const useReduxQueryCache = <D, P extends Object, QueryFn extends Function>(
  key: string,
  queryFn: QueryFn,
  options?: UseReduxQueryCacheOptions<D, P>
) => {
  const { data, params, loading, disabled, cacheTime } = options || {};

  useEffect(() => {
    if (!isMounted()) return;
    const cacheKeyState = cache.get(key) || {};
    const nowTime = new Date().getTime();
    cache.set(key, {
      ...cacheKeyState,
      timeOnRefetch:
        commonHelpers.isEmpty(cacheTime) || cacheTime === "infinity"
          ? 0
          : nowTime + (cacheTime as number),
    });
  }, [cacheTime]);

  useDeepCompareEffect(() => {
    const nowTime = new Date().getTime();
    let isTheFirstTime = false;
    if (!cache.has(key)) {
      isTheFirstTime = true;
      cache.set(key, {
        params,
        timeOnRefetch:
          commonHelpers.isEmpty(cacheTime) || cacheTime === "infinity"
            ? 0
            : nowTime + (cacheTime as number),
      });
    }

    const cacheKeyState = cache.get(key) || {};

    if (
      !loading &&
      !disabled &&
      ((typeof data !== "undefined" && commonHelpers.isEmpty(data)) ||
        (!commonHelpers.isEmpty(data) &&
          cacheTime !== "infinity" &&
          (cacheKeyState?.timeOnRefetch || 0) < nowTime) ||
        isTheFirstTime ||
        !_isEqual(params, cacheKeyState.params))
    ) {
      cache.set(key, {
        ...cacheKeyState,
        timeOnRefetch:
          commonHelpers.isEmpty(cacheTime) || cacheTime === "infinity"
            ? 0
            : nowTime + (cacheTime as number),
        params,
      });
      queryFn();
    }
  }, [key, params, disabled]);

  const isMounted = useIsMounted();

  return {
    refetch: queryFn,
  };
};

export default useReduxQueryCache;
