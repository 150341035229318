import { all, call, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { settingApi } from "@/utils/apis"

import { SettingActionTypes } from "./types"

import type { DeleteReservationWorkingDateSettingAction } from "./types"

function* deleteReservationWorkingDateSetting(
  action: DeleteReservationWorkingDateSettingAction,
) {
  const { params, cancelToken } = action.payload
  const { resolve } = action.meta || {}

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof settingApi.deleteReservationWorkingDateSetting>
    > = yield call(settingApi.deleteReservationWorkingDateSetting, {
      params,
      cancelToken,
    })

    resolve && resolve(response)
  } catch (e) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    resolve && resolve({ message })
  }
}

function* settingSaga() {
  yield all([
    takeEvery(
      SettingActionTypes.DELETE_RESERVATION_WORKING_DATE_SETTING_SAGA,
      deleteReservationWorkingDateSetting,
    ),
  ])
}

export default settingSaga
