import { UserActionTypes } from "./types"
import type { UserState, UserAction } from "./types"
import { commonHelpers } from "@/utils/helpers"

export const initialState: UserState = {
  users: [],
  usersCount: 0,
  usersError: "",
  usersLoading: false,

  user: null,
  userError: "",
  userLoading: false,
}

const reducer = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case UserActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      }
    }
    case UserActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        [`${scope}Error`]: "",
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      }
    }
    case UserActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload

      return {
        ...state,
        [`${scope}Error`]: error,
      }
    }

    case UserActionTypes.SET_USERS_FIELD_VALUES: {
      const { data, index } = action.payload

      return {
        ...state,
        users: state.users.map((user, userIndex) => {
          if (userIndex === index) {
            Object.assign(user, {
              ...data,
            })
          }
          return user
        }),
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
