import { all, call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { reservationHistoryApi } from "@/utils/apis"

import {
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
  mapFetchRequested,
  mapFetchSucceeded,
  mapFetchFailed,
  refundReservationSucceeded,
} from "./action"
import {
  FetchReservationHistorySagaAction,
  RefundReservationSagaAction,
  ReservationHistoryActionTypes,
} from "./types"

import type { FetchReservationHistoriesSagaAction } from "./types"

function* fetchReservationHistories(
  action: FetchReservationHistoriesSagaAction,
) {
  const { params, cancelToken } = action.payload || {}
  const { disabledLoading } = action.meta || {}

  if (!disabledLoading)
    yield put(
      fetchRequested({
        scope: "reservationHistories",
      }),
    )

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof reservationHistoryApi.fetchReservationHistories>
    > = yield call(reservationHistoryApi.fetchReservationHistories as any, {
      params,
      cancelToken,
    })

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "reservationHistories",
          data: response.data.list,
          count: response.data.count,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "reservationHistories",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "reservationHistories",
        error: message,
      }),
    )
  }
}

function* fetchReservationHistory(action: FetchReservationHistorySagaAction) {
  const { params, cancelToken } = action.payload || {}

  const key = params.id
  const scope = "idToReservationHistory"

  yield put(
    mapFetchRequested({
      scope,
      key,
    }),
  )

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof reservationHistoryApi.fetchReservationHistory>
    > = yield call(reservationHistoryApi.fetchReservationHistory, {
      params: params,
      cancelToken,
    })

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        mapFetchSucceeded({
          scope,
          key,
          data: response.data,
        }),
      )
    } else {
      yield put(
        mapFetchFailed({
          scope,
          key,
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      mapFetchFailed({
        scope,
        key,
        error: message,
      }),
    )
  }
}

function* fetchReservationHistoryChartData(
  action: FetchReservationHistoriesSagaAction,
) {
  const { params, cancelToken } = action.payload || {}

  yield put(
    fetchRequested({
      scope: "reservationHistoryChartData",
    }),
  )

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof reservationHistoryApi.fetchReservationHistoryChartData>
    > = yield call(
      reservationHistoryApi.fetchReservationHistoryChartData as any,
      {
        params,
        cancelToken,
      },
    )

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "reservationHistoryChartData",
          data: response.data,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "reservationHistoryChartData",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "reservationHistoryChartData",
        error: message,
      }),
    )
  }
}

function* refundReservation(action: RefundReservationSagaAction) {
  const { params, cancelToken } = action.payload
  const { resolve = () => {}, scope } = action.meta

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof reservationHistoryApi.refundReservation>> =
      yield call(reservationHistoryApi.refundReservation, {
        params,
        cancelToken,
      })

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        refundReservationSucceeded(response.data, {
          scope,
        }),
      )
    }
    resolve(response)
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    resolve({ message })
  }
}

function* reservationHistorySaga() {
  yield all([
    takeEvery(
      ReservationHistoryActionTypes.FETCH_RESERVATION_HISTORIES_SAGA,
      fetchReservationHistories,
    ),
    takeEvery(
      ReservationHistoryActionTypes.FETCH_RESERVATION_HISTORY_SAGA,
      fetchReservationHistory,
    ),
    takeEvery(
      ReservationHistoryActionTypes.FETCH_RESERVATION_HISTORY_CHART_DATA_SAGA,
      fetchReservationHistoryChartData,
    ),
    takeEvery(
      ReservationHistoryActionTypes.REFUND_RESERVATION_SAGA,
      refundReservation,
    ),
  ])
}

export default reservationHistorySaga
