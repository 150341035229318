import AppButton from "@/components/AppButton"
import AppContainer from "@/components/AppContainer"
import AppLink from "@/components/AppLink"
import { jwtService } from "@/services"
import { Typography } from "@mui/material"
import { Component } from "react"
import { withTranslation } from "next-i18next"

import { withStyles } from "tss-react/mui"

import type { WithTranslation } from "react-i18next"

interface Props extends WithTranslation<"translation", "common"> {
  children?: React.ReactNode
  classes?: Partial<Record<"root", string>>
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Uncaught error:", error.message)
    const token = jwtService.getToken()
    localStorage.clear()
    sessionStorage.clear()
    jwtService.saveToken(token)
  }

  public render() {
    const { children, t } = this.props
    const classes = this.props.classes!

    if (this.state.hasError) {
      return (
        <AppContainer maxWidth="md">
          <div className={classes.root}>
            <Typography variant="h1" fontWeight={700} marginBottom={0.5}>
              500
            </Typography>
            <Typography variant="h6" color="textSecondary" marginBottom={4}>
              {t("Oops! something went wrong")}
            </Typography>
            <AppButton
              variant="contained"
              component={AppLink}
              href="/dashboard">
              {t("Back to Dashboard")}
            </AppButton>
          </div>
        </AppContainer>
      )
    }

    return children
  }
}

const ErrorBoundaryStyled = withStyles(ErrorBoundary, theme => {
  return {
    root: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  }
})

const ErrorBoundaryStyledWithTranslation =
  withTranslation()(ErrorBoundaryStyled)

export default ErrorBoundaryStyledWithTranslation
