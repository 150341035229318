import { io } from "socket.io-client"

import { jwtService } from "@/services"

import { socketIOConstants } from "@/utils/constants"

import { useEffect, useMemo, useRef } from "react"

import SocketIOContext, { SocketIOContextProps } from "./SocketIO.context"

import { useAppSocketIO } from "@/hooks"

import type { Socket } from "socket.io-client"

type SocketIOProviderProps = {
  children: React.ReactNode
}

const SocketIOInitialization = () => {
  const { socket } = useAppSocketIO()

  return null
}

const SocketIOProvider = (props: SocketIOProviderProps) => {
  const { children } = props

  const socketRef = useRef<Socket | null>(null)

  const closeSocketIO = () => {
    socketRef.current && socketRef.current.close()
  }

  const connectSocketIO = () => {
    if (typeof window === "undefined" || !!socketRef.current) return
    const token = jwtService.getToken()
    socketRef.current = io(socketIOConstants.WEBSOCKET_ENDPOINT, {
      reconnectionDelayMax: 1000 * 10,
      reconnectionDelay: 1000 * 5,
      reconnectionAttempts: Infinity,
      reconnection: true,
      query: {
        token,
      },
    }).connect()
  }

  if (!socketRef.current) {
    connectSocketIO()
  }

  useEffect(() => {
    return () => {
      // closeSocketIO()
    }
  }, [])

  const value = useMemo<SocketIOContextProps>(() => {
    return {
      socket: socketRef.current!,
    }
  }, [])

  return (
    <SocketIOContext.Provider value={value!}>
      <SocketIOInitialization />
      {children}
    </SocketIOContext.Provider>
  )
}

export default SocketIOProvider
