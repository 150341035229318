import {
  DeleteUserAction,
  ResetUserPasswordAction,
  UserActionTypes,
} from "./types"
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchUserSagaAction,
  FetchUsersSagaAction,
  CreateUserSagaAction,
  UpdateUserSagaAction,
} from "./types"

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"],
): FetchRequestedAction => ({
  type: UserActionTypes.FETCH_REQUESTED,
  payload,
})

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"],
): FetchSucceededAction => ({
  type: UserActionTypes.FETCH_SUCCEEDED,
  payload,
})

export const fetchFailed = (
  payload: FetchFailedAction["payload"],
): FetchFailedAction => ({
  type: UserActionTypes.FETCH_FAILED,
  payload,
})

// ---- SAGA ----

export const fetchUsersSaga = (
  payload: FetchUsersSagaAction["payload"],
): FetchUsersSagaAction => ({
  type: UserActionTypes.FETCH_USERS_SAGA,
  payload,
})

export const fetchUserSaga = (
  payload: FetchUserSagaAction["payload"],
): FetchUserSagaAction => ({
  type: UserActionTypes.FETCH_USER_SAGA,
  payload,
})

export const createUserSaga = (
  payload: CreateUserSagaAction["payload"],
  meta?: CreateUserSagaAction["meta"],
): CreateUserSagaAction => ({
  type: UserActionTypes.CREATE_USER_SAGA,
  payload,
  meta,
})

export const updateUserSaga = (
  payload: UpdateUserSagaAction["payload"],
  meta?: UpdateUserSagaAction["meta"],
): UpdateUserSagaAction => ({
  type: UserActionTypes.UPDATE_USER_SAGA,
  payload,
  meta,
})

export const deleteUserSaga = (
  payload: DeleteUserAction["payload"],
  meta?: DeleteUserAction["meta"],
): DeleteUserAction => ({
  type: UserActionTypes.DELETE_USER_SAGA,
  payload,
  meta,
})

export const resetUserPasswordSaga = (
  payload: ResetUserPasswordAction["payload"],
  meta?: ResetUserPasswordAction["meta"],
): ResetUserPasswordAction => ({
  type: UserActionTypes.RESET_USER_PASSWORD_SAGA,
  payload,
  meta,
})
