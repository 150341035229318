import { FetchAllShopsPayload } from "@/utils/apis/shop/shop.api.types"

// Action Types

export enum ShopActionTypes {
  FETCH_REQUESTED = "@@branch/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@branch/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@branch/FETCH_FAILED",

  // Saga
  FETCH_ALL_SHOPS_SAGA = "@@branch/FETCH_ALL_SHOPS_SAGA",
}

// State

export interface ShopState {
  shops: {
    id: number
    name: string
    thumbnail: string
    address: string
    shopCode: string
    website: string
    phone: string
    mcId: string
  }[]
  shopsLoading: boolean
  shopsError: string
}

// ---- Reducer ----

export type FetchRequestedAction = {
  type: ShopActionTypes.FETCH_REQUESTED
  payload: {
    scope: "shops"
  }
}

export type FetchSucceededAction = {
  type: ShopActionTypes.FETCH_SUCCEEDED
  payload: {
    scope: "shops"
    data: ShopState[FetchSucceededAction["payload"]["scope"]]
  }
}

export type FetchFailedAction = {
  type: ShopActionTypes.FETCH_FAILED
  payload: {
    scope: "shops"
    error: string
  }
}

// ---- Saga ----

export type FetchShopsSagaAction = {
  type: ShopActionTypes.FETCH_ALL_SHOPS_SAGA
  payload: FetchAllShopsPayload
}

export type ShopAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchShopsSagaAction
