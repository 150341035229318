import type {
  CreateUserPayload,
  FetchUserPayload,
  FetchUsersPayload,
  UpdateUserPayload,
  DeleteUserPayload,
  ResetUserPasswordPayload,
} from "./user.api.types"
import { authAxios, AxiosResponseData } from "@/utils/axios"

const userApi = {
  fetchUsers: (payload: FetchUsersPayload) => {
    return authAxios.get<AxiosResponseData>("users", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    })
  },
  fetchUser: (payload: FetchUserPayload) => {
    const { id, ...params } = payload.params
    return authAxios.get<AxiosResponseData>(`users/${id}`, {
      params,
      cancelToken: payload.cancelToken,
    })
  },
  createUser: (payload: CreateUserPayload) => {
    return authAxios.post<AxiosResponseData>("users", payload.params, {
      cancelToken: payload.cancelToken,
    })
  },
  updateUser: (payload: UpdateUserPayload) => {
    const { id, ...params } = payload.params
    return authAxios.put<AxiosResponseData>(`users/${id}`, params, {
      cancelToken: payload.cancelToken,
    })
  },
  deleteUser: (payload: DeleteUserPayload) => {
    const { id } = payload.params
    return authAxios.delete<AxiosResponseData>(`users/${id}`, {
      cancelToken: payload.cancelToken,
    })
  },
  resetUserPassword: (payload: ResetUserPasswordPayload) => {
    const { id } = payload.params
    return authAxios.put<AxiosResponseData>(`users/${id}/default-password`, {
      cancelToken: payload.cancelToken,
    })
  },
}

export default userApi
