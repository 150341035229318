import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles<void, "edgeStart" | "edgeEnd">({
  name: "appButton",
  uniqId: "kta7lJ",
})((theme, _, classes) => {
  return {
    borderRadiusRounded: {
      borderRadius: theme.shape.borderRadius,
    },
    borderRadiusRoundedCircle: {
      borderRadius: "50px",
    },
    edgeStart: {
      marginLeft: theme.spacing(-1),
    },
    edgeEnd: {
      marginRight: theme.spacing(-1),
    },
    sizeSmall: {
      [`&.${classes.edgeStart}`]: {
        marginLeft: theme.spacing(-1.25),
      },
      [`&.${classes.edgeEnd}`]: {
        marginRight: theme.spacing(-1.25),
      },
    },
    sizeLarge: {
      [`&.${classes.edgeStart}`]: {
        marginLeft: theme.spacing(-2.75),
      },
      [`&.${classes.edgeEnd}`]: {
        marginRight: theme.spacing(-2.75),
      },
    },
  }
})

export default useStyles
