import { Theme } from "@mui/material/styles"

const styleOverrides = (theme: Theme) => {
  return `
    body {
      background-color: ${theme.palette.background.default}
    }
	`
}

export default styleOverrides
