import { all, call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { customerApi } from "@/utils/apis"

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action"
import { CustomerActionTypes } from "./types"

import type { FetchCustomersSagaAction, FetchCustomerSagaAction } from "./types"

function* fetchCustomers(action: FetchCustomersSagaAction) {
  const { params, cancelToken } = action.payload

  yield put(
    fetchRequested({
      scope: "customers",
    }),
  )

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof customerApi.fetchCustomers>> = yield call(
      customerApi.fetchCustomers,
      {
        params,
        cancelToken,
      },
    )

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "customers",
          data: response.data.list,
          count: response.data.count,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "customers",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "customers",
        error: message,
      }),
    )
  }
}

function* fetchCustomer(action: FetchCustomerSagaAction) {
  const { params, cancelToken } = action.payload

  yield put(
    fetchRequested({
      scope: "customer",
    }),
  )

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof customerApi.fetchCustomer>> = yield call(
      customerApi.fetchCustomer,
      {
        params,
        cancelToken,
      },
    )

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "customer",
          data: response.data,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "customer",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "customer",
        error: message,
      }),
    )
  }
}

function* customerSaga() {
  yield all([
    takeEvery(CustomerActionTypes.FETCH_CUSTOMERS_SAGA, fetchCustomers),
    takeEvery(CustomerActionTypes.FETCH_CUSTOMER_SAGA, fetchCustomer),
  ])
}

export default customerSaga
