import { all, call, put, takeLatest } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { jwtService } from "@/services"
import { authApi } from "@/utils/apis"

import { AuthActionTypes, SignInSagaAction } from "./types"
import {
  signInSucceeded,
  checkAuthRequested,
  checkAuthFailed,
  checkAuthSucceeded,
} from "./action"

function* signIn(action: SignInSagaAction) {
  const { params } = action.payload
  const { resolve } = action.meta || {}

  try {
    const { data: response } = yield call(authApi.signIn, {
      params,
    })
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        signInSucceeded({
          token: response.data.accessToken,
        }),
      )
    }
    resolve && resolve(response)
  } catch (e) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    resolve && resolve({ message })
  }
}

function* checkAuth() {
  const token = jwtService.getToken()
  if (!token) {
    yield put(checkAuthSucceeded(null))
    return
  }

  yield put(checkAuthRequested())

  try {
    const { data: response } = yield call(authApi.fetchUser)
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(checkAuthSucceeded(response.data))
    } else yield put(checkAuthFailed({ message: response.message }))
  } catch (e: any) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      checkAuthFailed({ message, isNetworkError: e.code === "ERR_NETWORK" }),
    )
  }
}

function* authSaga() {
  yield all([
    takeLatest(AuthActionTypes.SIGN_IN_SAGA, signIn),
    takeLatest(AuthActionTypes.CHECK_AUTH_SAGA, checkAuth),
  ])
}

export default authSaga
