import { authConstants } from "@/utils/constants"
import type { User } from "@/store/auth/types"

const AUTH_TOKEN_KEY = authConstants.AUTH_TOKEN_KEY
const USER_PROFILE_KEY = authConstants.USER_PROFILE_KEY

export const getToken = () => {
  if (typeof window === "undefined") return
  return JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY) as any)
}

export const saveToken = (token: string) => {
  if (typeof window === "undefined") return
  localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(token))
}

export const destroyToken = () => {
  if (typeof window === "undefined") return
  localStorage.removeItem(AUTH_TOKEN_KEY)
}

export const saveUserProfile = (user: Partial<User> | null) => {
  if (typeof window === "undefined") return
  return localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(user))
}

export const getUserProfile = (): User | null => {
  if (typeof window === "undefined") return null
  try {
    return JSON.parse(localStorage.getItem(USER_PROFILE_KEY) as any)
  } catch {
    return null
  }
}

export const destroyUserProfile = () => {
  localStorage.removeItem(USER_PROFILE_KEY)
}
