import { AuthActionTypes } from "./types"
import type {
  SignOutAction,
  CheckAuthRequestedAction,
  CheckAuthFailedAction,
  CheckAuthSucceededAction,
  SignInSucceededAction,
  // Saga
  SignInSagaAction,
  CheckAuthSagaAction,
} from "./types"

// ---- REDUCER ACTION ----

// Common Fetch Action
export const checkAuthRequested = (): CheckAuthRequestedAction => ({
  type: AuthActionTypes.CHECK_AUTH_REQUESTED,
})

export const checkAuthSucceeded = (
  payload: CheckAuthSucceededAction["payload"],
): CheckAuthSucceededAction => ({
  type: AuthActionTypes.CHECK_AUTH_SUCCEEDED,
  payload,
})

export const checkAuthFailed = (
  payload: CheckAuthFailedAction["payload"],
): CheckAuthFailedAction => ({
  type: AuthActionTypes.CHECK_AUTH_FAILED,
  payload,
})

export const signInSucceeded = (
  payload: SignInSucceededAction["payload"],
): SignInSucceededAction => ({
  type: AuthActionTypes.SIGN_IN_SUCCEEDED,
  payload,
})

export const signOut = (): SignOutAction => ({
  type: AuthActionTypes.SIGN_OUT,
})

// ---- SAGA ACTION ----

export const signInSaga = (
  payload: SignInSagaAction["payload"],
  meta?: SignInSagaAction["meta"],
): SignInSagaAction => ({
  type: AuthActionTypes.SIGN_IN_SAGA,
  payload,
  meta,
})

export const checkAuthSaga = (): CheckAuthSagaAction => ({
  type: AuthActionTypes.CHECK_AUTH_SAGA,
})
