import type {
  FetchBookingRemarkPayload,
  UpdateBookingRemarkPayload,
} from "./bookingRemark.api.types"
import { authAxios, AxiosResponseData } from "@/utils/axios"

const cmsPermissionApi = {
  fetchBookingRemark: (payload: FetchBookingRemarkPayload) => {
    return authAxios.get<AxiosResponseData>("remark-enum", {
      cancelToken: payload.cancelToken,
    })
  },
  updateBookingRemark: (payload: UpdateBookingRemarkPayload) => {
    return authAxios.put<AxiosResponseData>("remark-enum", payload.params, {
      cancelToken: payload.cancelToken,
    })
  },
}

export default cmsPermissionApi
