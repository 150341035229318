import type { SettingState, SettingAction } from "./types"

export const initialState: SettingState = {}

const reducer = (state = initialState, action: SettingAction) => {
  switch (action.type) {
    default: {
      return state
    }
  }
}

export default reducer
