import type {
  FetchCustomerPayload,
  FetchCustomersPayload,
} from "./customer.api.types"
import { authAxios, AxiosResponseData } from "@/utils/axios"

const customerApi = {
  fetchCustomers: (payload: FetchCustomersPayload) => {
    return authAxios.get<AxiosResponseData>("guests", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    })
  },
  fetchCustomer: (payload: FetchCustomerPayload) => {
    const { id, ...params } = payload.params
    return authAxios.get<AxiosResponseData>(`guests/${id}`, {
      params,
      cancelToken: payload.cancelToken,
    })
  },
}

export default customerApi
