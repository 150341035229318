// Action Types
import type {
  FetchCustomersPayload,
  FetchCustomerPayload,
  FetchCustomersResponseData,
  FetchCustomerResponseData,
} from "@/utils/apis/customer/customer.api.types"

export enum CustomerActionTypes {
  FETCH_REQUESTED = "@@customer/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@customer/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@customer/FETCH_FAILED",
  SET_CUSTOMERS_FIELD_VALUES = "@@customer/SET_CUSTOMERS_FIELD_VALUES",

  // Saga
  FETCH_CUSTOMERS_SAGA = "@@customer/FETCH_CUSTOMERS_SAGA",
  FETCH_CUSTOMER_SAGA = "@@customer/FETCH_CUSTOMER_SAGA",
}

// State

export interface CustomerState {
  customers: FetchCustomersResponseData[]
  customersCount: number
  customersLoading: boolean
  customersError: string

  customer: FetchCustomerResponseData | null
  customerLoading: boolean
  customerError: string
}

// ---- Reducer ----

export type FetchRequestedAction = {
  type: CustomerActionTypes.FETCH_REQUESTED
  payload: {
    scope: "customer" | "customers"
  }
}

export type FetchSucceededAction = {
  type: CustomerActionTypes.FETCH_SUCCEEDED
  payload: {
    scope: "customer" | "customers"
    data: CustomerState[FetchSucceededAction["payload"]["scope"]]
    count?: number
  }
}

export type FetchFailedAction = {
  type: CustomerActionTypes.FETCH_FAILED
  payload: {
    scope: "customer" | "customers"
    error: string
  }
}

// ---- Saga ----

export type FetchCustomersSagaAction = {
  type: CustomerActionTypes.FETCH_CUSTOMERS_SAGA
  payload: FetchCustomersPayload
}

export type FetchCustomerSagaAction = {
  type: CustomerActionTypes.FETCH_CUSTOMER_SAGA
  payload: FetchCustomerPayload
}

export type CustomerAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchCustomersSagaAction
  | FetchCustomerSagaAction
