import { authAxios, AxiosResponseData } from "@/utils/axios"

import type {
  FetchReservationHistoriesPayload,
  FetchReservationHistoryChartDataPayload,
  FetchReservationHistoryPayload,
  RefundReservationPayload,
  FetchV2ReservationHistoryPayload,
} from "./reservationHistory.api.types"

const reservationHistoryApi = {
  fetchReservationHistories: (payload: FetchReservationHistoriesPayload) => {
    return authAxios.get<AxiosResponseData>("reservation-history", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    })
  },
  fetchReservationHistory: (payload: FetchReservationHistoryPayload) => {
    return authAxios.get<AxiosResponseData>(
      `reservation-history/${payload.params.id}`,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },
  fetchV2ReservationHistory: (payload: FetchV2ReservationHistoryPayload) => {
    return authAxios.get<AxiosResponseData>(
      `reservation-history/update-socket/${payload.params.id}`,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },
  fetchReservationHistoryChartData: (
    payload: FetchReservationHistoryChartDataPayload,
  ) => {
    return authAxios.get<AxiosResponseData>("reservation-history/chart-days", {
      params: payload.params,
      cancelToken: payload.cancelToken,
    })
  },
  refundReservation: (payload: RefundReservationPayload) => {
    const { id, ...restParams } = payload.params
    return authAxios.post<AxiosResponseData>(
      `reservation/refund/${payload.params.id}`,
      restParams,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },
}

export default reservationHistoryApi
