import moment from "moment"

import { useTranslation } from "next-i18next"

const useAppMomentWithLocale = () => {
  const { i18n } = useTranslation()
  const momentWithLocale = moment
  momentWithLocale.locale(i18n.language)

  return {
    momentWithLocale,
  }
}

export default useAppMomentWithLocale
