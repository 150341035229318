import { ReservationHistoryActionTypes } from "./types"
import { commonHelpers } from "@/utils/helpers"

import type { ReservationHistoryState, ReservationHistoryAction } from "./types"

export const initialState: ReservationHistoryState = {
  reservationHistories: [],
  reservationHistoriesError: "",
  reservationHistoriesLoading: false,
  reservationHistoriesCount: 0,

  idToReservationHistoryErrorMap: {},
  idToReservationHistoryLoadingMap: {},
  idToReservationHistoryMap: {},

  reservationHistoryChartData: [],
  reservationHistoryChartDataError: "",
  reservationHistoryChartDataLoading: false,
}

const reducer = (state = initialState, action: ReservationHistoryAction) => {
  switch (action.type) {
    case ReservationHistoryActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      }
    }
    case ReservationHistoryActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        [`${scope}Error`]: "",
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      }
    }
    case ReservationHistoryActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload

      return {
        ...state,
        [`${scope}Error`]: error,
      }
    }

    case ReservationHistoryActionTypes.MAP_FETCH_REQUESTED: {
      const { scope, key } = action.payload
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: true,
      }
      const errorMap = {
        ...state[`${scope}LoadingMap`],
        [key]: "",
      }
      Object.entries(loadingMap).length >= 100 &&
        delete loadingMap[Object.entries(loadingMap)[0]?.[0]]
      Object.entries(errorMap).length >= 100 &&
        delete errorMap[Object.entries(errorMap)[0]?.[0]]

      return {
        ...state,
        [`${scope}LoadingMap`]: loadingMap,
        [`${scope}ErrorMap`]: errorMap,
      }
    }
    case ReservationHistoryActionTypes.MAP_FETCH_SUCCEEDED: {
      const { scope, key, data } = action.payload
      const dataMap = {
        ...state[`${scope}Map`],
        [key]: data,
      }
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: false,
      }
      Object.entries(dataMap).length >= 100 &&
        delete dataMap[Object.entries(dataMap)[0]?.[0]]

      return {
        ...state,
        [`${scope}Map`]: dataMap,
        [`${scope}LoadingMap`]: loadingMap,
      }
    }
    case ReservationHistoryActionTypes.MAP_FETCH_FAILED: {
      const { scope, key, error } = action.payload
      const loadingMap = {
        ...state[`${scope}LoadingMap`],
        [key]: false,
      }
      const errorMap = {
        ...state[`${scope}LoadingMap`],
        [key]: error,
      }

      return {
        ...state,
        [`${scope}LoadingMap`]: loadingMap,
        [`${scope}ErrorMap`]: errorMap,
      }
    }

    case ReservationHistoryActionTypes.REFUND_RESERVATION_SUCCEEDED: {
      const {
        id,
        beginRefundDate,
        completeRefundDate,
        endRefundDate,
        isCompleteRefund,
      } = action.payload
      const { scope } = action.meta

      switch (scope) {
        case "reservationHistories": {
          const newReservationHistories = state.reservationHistories.map(
            reservationHistory => {
              if (reservationHistory.id === id) {
                reservationHistory.beginRefundDate = beginRefundDate
                reservationHistory.completeRefundDate = completeRefundDate
                reservationHistory.endRefundDate = endRefundDate
                reservationHistory.isCompleteRefund = isCompleteRefund
              }
              return { ...reservationHistory }
            },
          )

          return {
            ...state,
            reservationHistories: newReservationHistories,
          }
        }
        case "idToReservationHistoryMap": {
          if (!!state.idToReservationHistoryMap[id]) {
            const newReservationHistory = {
              ...state.idToReservationHistoryMap[id],
            }
            newReservationHistory.beginRefundDate = beginRefundDate
            newReservationHistory.completeRefundDate = completeRefundDate
            newReservationHistory.endRefundDate = endRefundDate
            newReservationHistory.isCompleteRefund = isCompleteRefund
            return {
              ...state,
              idToReservationHistoryMap: {
                [id]: newReservationHistory,
              },
            }
          }
        }
      }

      return state
    }

    case ReservationHistoryActionTypes.UPDATE_RESERVATION: {
      const { id, ...rest } = action.payload
      const { scope } = action.meta

      switch (scope) {
        case "reservationHistories": {
          const newReservationHistories = state.reservationHistories.map(
            reservationHistory => {
              if (reservationHistory.id === id) {
                return {
                  ...reservationHistory,
                  ...rest,
                }
              }
              return { ...reservationHistory }
            },
          )

          return {
            ...state,
            reservationHistories: newReservationHistories,
          }
        }
        case "idToReservationHistoryMap": {
          if (!!state.idToReservationHistoryMap[id]) {
            const newReservationHistory = {
              ...state.idToReservationHistoryMap[id],
              ...rest,
            }
            return {
              ...state,
              idToReservationHistoryMap: {
                [id]: newReservationHistory,
              },
            }
          }
        }
      }

      return state
    }
    default: {
      return state
    }
  }
}

export default reducer
