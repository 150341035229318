import {
  FetchReservationHistorySagaAction,
  MapFetchFailedAction,
  MapFetchRequestedAction,
  MapFetchSucceededAction,
  ReservationHistoryActionTypes,
} from "./types"
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  RefundReservationSucceeded,
  UpdateReservation,
  // Saga
  FetchReservationHistoriesSagaAction,
  FetchReservationHistoryChartDataSagaAction,
  RefundReservationSagaAction,
} from "./types"

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"],
): FetchRequestedAction => ({
  type: ReservationHistoryActionTypes.FETCH_REQUESTED,
  payload,
})

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"],
): FetchSucceededAction => ({
  type: ReservationHistoryActionTypes.FETCH_SUCCEEDED,
  payload,
})

export const fetchFailed = (
  payload: FetchFailedAction["payload"],
): FetchFailedAction => ({
  type: ReservationHistoryActionTypes.FETCH_FAILED,
  payload,
})

export const mapFetchRequested = (
  payload: MapFetchRequestedAction["payload"],
): MapFetchRequestedAction => ({
  type: ReservationHistoryActionTypes.MAP_FETCH_REQUESTED,
  payload,
})

export const mapFetchSucceeded = (
  payload: MapFetchSucceededAction["payload"],
): MapFetchSucceededAction => ({
  type: ReservationHistoryActionTypes.MAP_FETCH_SUCCEEDED,
  payload,
})

export const mapFetchFailed = (
  payload: MapFetchFailedAction["payload"],
): MapFetchFailedAction => ({
  type: ReservationHistoryActionTypes.MAP_FETCH_FAILED,
  payload,
})

export const refundReservationSucceeded = (
  payload: RefundReservationSucceeded["payload"],
  meta: RefundReservationSucceeded["meta"],
): RefundReservationSucceeded => ({
  type: ReservationHistoryActionTypes.REFUND_RESERVATION_SUCCEEDED,
  payload,
  meta,
})

export const updateReservation = (
  payload: UpdateReservation["payload"],
  meta: UpdateReservation["meta"],
): UpdateReservation => ({
  type: ReservationHistoryActionTypes.UPDATE_RESERVATION,
  payload,
  meta,
})

// ---- SAGA ----

export const fetchReservationHistoriesSaga = (
  payload?: FetchReservationHistoriesSagaAction["payload"],
  meta?: FetchReservationHistoriesSagaAction["meta"],
): FetchReservationHistoriesSagaAction => ({
  type: ReservationHistoryActionTypes.FETCH_RESERVATION_HISTORIES_SAGA,
  payload,
  meta,
})

export const fetchReservationHistorySaga = (
  payload: FetchReservationHistorySagaAction["payload"],
  meta?: FetchReservationHistorySagaAction["meta"],
): FetchReservationHistorySagaAction => ({
  type: ReservationHistoryActionTypes.FETCH_RESERVATION_HISTORY_SAGA,
  payload,
  meta,
})

export const fetchReservationHistoryChartDataSaga = (
  payload?: FetchReservationHistoryChartDataSagaAction["payload"],
  meta?: FetchReservationHistoryChartDataSagaAction["meta"],
): FetchReservationHistoryChartDataSagaAction => ({
  type: ReservationHistoryActionTypes.FETCH_RESERVATION_HISTORY_CHART_DATA_SAGA,
  payload,
  meta,
})

export const refundReservationSaga = (
  payload: RefundReservationSagaAction["payload"],
  meta: RefundReservationSagaAction["meta"],
): RefundReservationSagaAction => ({
  type: ReservationHistoryActionTypes.REFUND_RESERVATION_SAGA,
  payload,
  meta,
})
