import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(theme => {
  return {
    dialogPaper: {},
    dialogTitle: {},
    dialogContent: {},
    dialogActions: {
      gap: theme.spacing(2),
      justifyContent: "center",
      padding: theme.spacing(0, 2, 2),
      "> .MuiButton-root": {
        marginLeft: 0,
      },
    },
  }
})

export default useStyles
