import { useSocketIOContext } from "@/contexts/SocketIO"

import { useEffect } from "react"
import useEventCallback from "./useEventCallback"

import { FetchReservationHistoryResponseData } from "@/utils/apis/reservationHistory/reservationHistory.api.types"

type ReservationHistoryData = Pick<
  FetchReservationHistoryResponseData,
  | "id"
  | "completeRefundDate"
  | "isCompleteRefund"
  | "beginRefundDate"
  | "endRefundDate"
  | "refundAmount"
  | "refundReason"
  | "isActive"
  | "booking"
  | "deposits"
  | "transactionId"
>

type AppSocketIOProps = {
  onConnect?: () => void
  onReservationRefundBegin?: (data: ReservationHistoryData) => void
  onReservationRefundSucceed?: (data: ReservationHistoryData) => void
  onReservationRefundFail?: (data: ReservationHistoryData) => void
}

const useAppSocketIO = (props?: AppSocketIOProps) => {
  const {
    onConnect = () => {},
    onReservationRefundBegin = () => {},
    onReservationRefundFail = () => {},
    onReservationRefundSucceed = () => {},
  } = props || {}

  const { socket } = useSocketIOContext()

  const executeConnect = useEventCallback(onConnect)
  const executeReservationRefundBegin = useEventCallback(
    onReservationRefundBegin,
  )
  const executeReservationRefundFail = useEventCallback(onReservationRefundFail)
  const executeReservationRefundSucceed = useEventCallback(
    onReservationRefundSucceed,
  )

  useEffect(() => {
    socket && socket.on("connection", executeConnect)
    return () => {
      socket && socket.off("connection", executeConnect)
    }
  }, [])

  useEffect(() => {
    socket && socket.on("refundBegin", executeReservationRefundBegin)
    return () => {
      socket && socket.off("refundBegin", executeReservationRefundBegin)
    }
  }, [])

  useEffect(() => {
    socket && socket.on("refundSuccess", executeReservationRefundFail)
    return () => {
      socket && socket.off("refundSuccess", executeReservationRefundFail)
    }
  }, [])

  useEffect(() => {
    socket && socket.on("refundFailure", executeReservationRefundSucceed)
    return () => {
      socket && socket.off("refundFailure", executeReservationRefundSucceed)
    }
  }, [])

  return {
    socket,
    methods: {},
  }
}

export default useAppSocketIO
