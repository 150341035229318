import moment from "moment"

export const isMobile = () => {
  return (
    typeof window !== "undefined" &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  )
}

export const isEmpty = (val: any) => {
  return ["", null, undefined].includes(val)
}

export const isNumber = (number: any) => {
  return !isEmpty(number) && !isNaN(Number(number))
}

export const formatNumber = (
  number?: number | string,
  options?: Intl.NumberFormatOptions,
) => {
  if (!isNumber(number)) return number
  const locale = "en-US"
  return new Intl.NumberFormat(locale, options).format(Number(number))
}

export const decodeHTML = (input: string) => {
  const e = document.createElement("textarea")
  e.innerHTML = input
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue || ""
}

export const checkOverlappingTime = (
  timeSegments: string[][],
  options?: { format?: string },
) => {
  if (timeSegments.length === 1) return false

  const timeValid = timeSegments.every(
    timeSegment =>
      moment(timeSegment[0], options?.format).isValid() &&
      moment(timeSegment[1], options?.format).isValid(),
  )
  if (!timeValid) return false

  timeSegments.sort((timeSegment1, timeSegment2) =>
    timeSegment1[0].localeCompare(timeSegment2[0]),
  )

  for (let i = 0; i < timeSegments.length - 1; i++) {
    const currentEndTime = timeSegments[i][1]
    const nextStartTime = timeSegments[i + 1][0]

    if (currentEndTime > nextStartTime) {
      return true
    }
  }

  return false
}

export const getProgressBarValue = (
  value: number,
  [MIN, MAX]: [MIN: number, MAX: number],
) => ((value - MIN) * 100) / (MAX - MIN)
