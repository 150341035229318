import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { appVersionApi } from "@/utils/apis"

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action"
import { AppVersionActionTypes } from "./types"

import type {
  FetchAppVersionsSagaAction,
  FetchAppVersionSagaAction,
  UpdateAppVersionSagaAction,
} from "./types"

function* fetchAppVersions(action: FetchAppVersionsSagaAction) {
  const { params, cancelToken } = action.payload

  yield put(
    fetchRequested({
      scope: "appVersions",
    }),
  )

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appVersionApi.fetchAppVersions>> = yield call(
      appVersionApi.fetchAppVersions,
      {
        params,
        cancelToken,
      },
    )

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "appVersions",
          data: response.data.list,
          count: response.data.count,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "appVersions",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "appVersions",
        error: message,
      }),
    )
  }
}

function* fetchAppVersion(action: FetchAppVersionSagaAction) {
  const { params, cancelToken } = action.payload

  yield put(
    fetchRequested({
      scope: "appVersion",
    }),
  )

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appVersionApi.fetchAppVersion>> = yield call(
      appVersionApi.fetchAppVersion,
      {
        params,
        cancelToken,
      },
    )

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "appVersion",
          data: response.data,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "appVersion",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "appVersion",
        error: message,
      }),
    )
  }
}

function* updateAppVersion(action: UpdateAppVersionSagaAction) {
  const { params, cancelToken } = action.payload
  const { resolve } = action.meta || {}

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appVersionApi.updateAppVersion>> = yield call(
      appVersionApi.updateAppVersion,
      {
        params,
        cancelToken,
      },
    )
    resolve && resolve(response)
  } catch (e) {
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    resolve && resolve({ message })
  }
}

function* appVersionSaga() {
  yield all([
    takeEvery(AppVersionActionTypes.FETCH_APP_VERSIONS_SAGA, fetchAppVersions),
    takeEvery(AppVersionActionTypes.FETCH_APP_VERSION_SAGA, fetchAppVersion),
    takeLatest(AppVersionActionTypes.UPDATE_APP_VERSION_SAGA, updateAppVersion),
  ])
}

export default appVersionSaga
