import { CustomerActionTypes } from "./types"
import type { CustomerState, CustomerAction } from "./types"
import { commonHelpers } from "@/utils/helpers"

export const initialState: CustomerState = {
  customers: [],
  customersCount: 0,
  customersError: "",
  customersLoading: false,

  customer: null,
  customerError: "",
  customerLoading: false,
}

const reducer = (state = initialState, action: CustomerAction) => {
  switch (action.type) {
    case CustomerActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      }
    }
    case CustomerActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
        [`${scope}Error`]: "",
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      }
    }
    case CustomerActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload

      return {
        ...state,
        [`${scope}Error`]: error,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
