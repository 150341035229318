import { AuthActionTypes, User } from "./types"
import type { AuthState, AuthAction } from "./types"
import { jwtService } from "@/services"

export const initialState: AuthState = {
  user: null,
  userAuthChecking: true,
  userAuthCheckingErrorMessage: "",
}

const reducer = (state = initialState, action: AuthAction) => {
  switch (action.type) {
    case AuthActionTypes.CHECK_AUTH_REQUESTED: {
      return {
        ...state,
        userAuthChecking: true,
        userAuthCheckingErrorMessage: "",
      }
    }
    case AuthActionTypes.CHECK_AUTH_SUCCEEDED: {
      if (!action.payload)
        return {
          ...state,
          user: null,
          userAuthChecking: false,
        }
      const user = action.payload as User
      return {
        ...state,
        user: {
          ...user,
        },
        userAuthChecking: false,
      }
    }
    case AuthActionTypes.CHECK_AUTH_FAILED: {
      const { message, isNetworkError } = action.payload

      if (!isNetworkError) {
        jwtService.destroyToken()
      }

      return {
        ...state,
        user: isNetworkError ? state.user : null,
        userAuthCheckingErrorMessage: message,
        userAuthChecking: !!isNetworkError,
      }
    }

    case AuthActionTypes.SIGN_IN_SUCCEEDED: {
      jwtService.saveToken(action.payload.token)
      return {
        ...state,
        user: {
          token: action.payload.token,
        } as User,
      }
    }

    case AuthActionTypes.SIGN_OUT: {
      jwtService.destroyToken()
      return {
        ...state,
        user: null,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
