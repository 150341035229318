// Action Types
import type { SignInPayload } from "@/utils/apis/auth/auth.api.types"

export enum AuthActionTypes {
  SIGN_IN_SUCCEEDED = "@@auth/SIGN_IN_SUCCEEDED",

  CHECK_AUTH_REQUESTED = "@@auth/CHECK_AUTH_REQUESTED",
  CHECK_AUTH_SUCCEEDED = "@@auth/CHECK_AUTH_SUCCEEDED",
  CHECK_AUTH_FAILED = "@@auth/CHECK_AUTH_FAILED",

  SIGN_OUT = "@@auth/SIGN_OUT",

  // Saga
  CHECK_AUTH_SAGA = "@@auth/CHECK_AUTH_SAGA",
  SIGN_IN_SAGA = "@@auth/SIGN_IN_SAGA",
}

// State

export interface User {
  id: number
  userName: string
  email: string
  token: string
  isAdmin: boolean
  isSupperAdmin: boolean
  canRefund: boolean
  permissions: {
    id: number
    name: string
    description: string
  }[]
}

export interface AuthState {
  user: User | null
  userAuthChecking: boolean
  userAuthCheckingErrorMessage: string
}

// ---- Reducer Action ----

export type SignInSucceededAction = {
  type: AuthActionTypes.SIGN_IN_SUCCEEDED
  payload: {
    token: string
  }
}

export type CheckAuthRequestedAction = {
  type: AuthActionTypes.CHECK_AUTH_REQUESTED
}

export type CheckAuthFailedAction = {
  type: AuthActionTypes.CHECK_AUTH_FAILED
  payload: {
    message: string
    isNetworkError?: boolean
  }
}

export type CheckAuthSucceededAction = {
  type: AuthActionTypes.CHECK_AUTH_SUCCEEDED
  payload: User | null
}

export type SignOutAction = {
  type: AuthActionTypes.SIGN_OUT
}

// ---- Saga Action ----

export type SignInSagaAction = {
  type: AuthActionTypes.SIGN_IN_SAGA
  payload: SignInPayload
  meta?: {
    resolve?: (payload?: any) => void
  }
}

export type CheckAuthSagaAction = {
  type: AuthActionTypes.CHECK_AUTH_SAGA
}

export type AuthAction =
  | CheckAuthRequestedAction
  | CheckAuthSucceededAction
  | CheckAuthFailedAction
  | SignInSucceededAction
  | SignOutAction
  | CheckAuthSagaAction
  | SignInSagaAction
