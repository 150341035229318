import { AppVersionActionTypes } from "./types"
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchAppVersionSagaAction,
  FetchAppVersionsSagaAction,
  UpdateAppVersionSagaAction,
} from "./types"

// ---- REDUCER ----
export const fetchRequested = (
  payload: FetchRequestedAction["payload"],
): FetchRequestedAction => ({
  type: AppVersionActionTypes.FETCH_REQUESTED,
  payload,
})

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"],
): FetchSucceededAction => ({
  type: AppVersionActionTypes.FETCH_SUCCEEDED,
  payload,
})

export const fetchFailed = (
  payload: FetchFailedAction["payload"],
): FetchFailedAction => ({
  type: AppVersionActionTypes.FETCH_FAILED,
  payload,
})

// ---- SAGA ----

export const fetchAppVersionsSaga = (
  payload: FetchAppVersionsSagaAction["payload"],
): FetchAppVersionsSagaAction => ({
  type: AppVersionActionTypes.FETCH_APP_VERSIONS_SAGA,
  payload,
})

export const fetchAppVersionSaga = (
  payload: FetchAppVersionSagaAction["payload"],
): FetchAppVersionSagaAction => ({
  type: AppVersionActionTypes.FETCH_APP_VERSION_SAGA,
  payload,
})

export const updateAppVersionSaga = (
  payload: UpdateAppVersionSagaAction["payload"],
  meta?: UpdateAppVersionSagaAction["meta"],
): UpdateAppVersionSagaAction => ({
  type: AppVersionActionTypes.UPDATE_APP_VERSION_SAGA,
  payload,
  meta,
})
