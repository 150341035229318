import { ShopActionTypes } from "./types"

import type { ShopState, ShopAction } from "./types"

export const initialState: ShopState = {
  shops: [],
  shopsError: "",
  shopsLoading: false,
}

const reducer = (state = initialState, action: ShopAction) => {
  switch (action.type) {
    case ShopActionTypes.FETCH_REQUESTED: {
      const { scope } = action.payload

      return {
        ...state,
        [`${scope}Loading`]: true,
        [`${scope}Error`]: "",
      }
    }
    case ShopActionTypes.FETCH_SUCCEEDED: {
      const { scope, data } = action.payload

      return {
        ...state,
        [scope]: data,
        [`${scope}Loading`]: false,
      }
    }

    case ShopActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload

      return {
        ...state,
        [`${scope}Error`]: error,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
