import { useCallback, useRef } from "react"

import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect"

const useEventCallback = <Fn extends Function>(fn?: Fn): Fn => {
  var ref = useRef(fn) // we copy a ref to the callback scoped to the current state/props on each render

  useIsomorphicLayoutEffect(() => {
    ref.current = fn
  })
  return useCallback(function () {
    for (
      var _len = arguments.length, args = new Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key]
    }
    return ref.current ? ref.current.apply(void 0, args) : null
  }, []) as unknown as Fn
}

export default useEventCallback
