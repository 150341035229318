import { all, call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { axiosHelpers } from "@/utils/helpers"
import { userLogApi } from "@/utils/apis"

import { fetchRequested, fetchSucceeded, fetchFailed } from "./action"
import { UserLogActionTypes } from "./types"

import type { FetchUserLogsSagaAction } from "./types"

function* fetchUserLogs(action: FetchUserLogsSagaAction) {
  const { params, cancelToken } = action.payload

  yield put(
    fetchRequested({
      scope: "userLogs",
    }),
  )

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof userLogApi.fetchUserLogs>> = yield call(
      userLogApi.fetchUserLogs,
      {
        params,
        cancelToken,
      },
    )

    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope: "userLogs",
          data: response.data.list,
          count: response.data.count,
        }),
      )
    } else {
      yield put(
        fetchFailed({
          scope: "userLogs",
          error: response.message,
        }),
      )
    }
  } catch (e) {
    if (axios.isCancel(e)) return
    const message = axios.isAxiosError(e)
      ? (e.response?.data as any)?.message || e.message
      : ""
    yield put(
      fetchFailed({
        scope: "userLogs",
        error: message,
      }),
    )
  }
}

function* userLogSaga() {
  yield all([takeEvery(UserLogActionTypes.FETCH_USER_LOGS_SAGA, fetchUserLogs)])
}

export default userLogSaga
