import { authAxios, AxiosResponseData } from "@/utils/axios"
import {
  FetchBranchReservationSettingPayload,
  FetchBranchReservationWorkingDateSettingsPayload,
  FetchBranchReservationLockingDateSettingsPayload,
  FetchBranchReservationNoticeSettingsPayload,
  SaveReservationWorkingDateSettingWithMultiBranchPayload,
  SaveReservationLockingDateSettingWithMultiBranchPayload,
  SaveReservationNoticeSettingWithMultiBranchPayload,
  SaveReservationEndLunchTimeSettingWithMultiBranchPayload,
  UpdateReservationWorkingDateSettingPayload,
  UpdateReservationNoticeSettingPayload,
  UpdateReservationEndLunchTimeSettingPayload,
  UpdateReservationLockingDateSettingPayload,
  DeleteReservationWorkingDateSettingPayload,
  SaveReservationOverdueSettingWithMultiBranchPayload,
  SaveReservationBeforeTimeSettingWithMultiBranchPayload,
  SaveReservationBabySeatSettingWithMultiBranchPayload,
  UpdateReservationOverdueSettingPayload,
  UpdateReservationBeforeTimeSettingPayload,
  UpdateReservationBabySeatSettingPayload,
  DeleteReservationNoticeSettingPayload,
  DeleteReservationLockingDateSettingPayload,
} from "./setting.api.types"

const settingApi = {
  fetchBranchReservationSetting: (
    payload: FetchBranchReservationSettingPayload,
  ) => {
    return authAxios.get<AxiosResponseData>(
      `settings/branch/${payload.params.branchId}/reservation-setting`,
      {
        cancelToken: payload.cancelToken,
        params: payload.params,
      },
    )
  },

  fetchBranchReservationWorkingDateSettings: (
    payload: FetchBranchReservationWorkingDateSettingsPayload,
  ) => {
    return authAxios.get<AxiosResponseData>(
      "settings/working-date-settings-by-branch",
      {
        cancelToken: payload.cancelToken,
        params: payload.params,
      },
    )
  },

  fetchBranchReservationLockingDateSettings: (
    payload: FetchBranchReservationLockingDateSettingsPayload,
  ) => {
    return authAxios.get<AxiosResponseData>(
      "settings/locking-date-settings-by-branch",
      {
        cancelToken: payload.cancelToken,
        params: payload.params,
      },
    )
  },

  fetchBranchReservationNoticeSettings: (
    payload: FetchBranchReservationNoticeSettingsPayload,
  ) => {
    return authAxios.get<AxiosResponseData>(
      "settings/reservation-notices-by-branch",
      {
        cancelToken: payload.cancelToken,
        params: payload.params,
      },
    )
  },

  deleteReservationWorkingDateSetting: (
    payload: DeleteReservationWorkingDateSettingPayload,
  ) => {
    return authAxios.delete<AxiosResponseData>(
      `settings/working-date-settings/${payload.params.id}`,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },
  deleteReservationNoticeSetting: (
    payload: DeleteReservationNoticeSettingPayload,
  ) => {
    return authAxios.delete<AxiosResponseData>(
      `settings/reservation-notices/${payload.params.id}`,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },
  deleteReservationLockingDateSetting: (
    payload: DeleteReservationLockingDateSettingPayload,
  ) => {
    return authAxios.delete<AxiosResponseData>(
      `settings/locking-date-settings/${payload.params.id}`,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  saveReservationWorkingDateSettingWithMultiBranch: (
    payload: SaveReservationWorkingDateSettingWithMultiBranchPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/multi-working-date-settings",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  saveReservationLockingDateSettingWithMultiBranch: (
    payload: SaveReservationLockingDateSettingWithMultiBranchPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/multi-locking-date",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  saveReservationNoticeSettingWithMultiBranch: (
    payload: SaveReservationNoticeSettingWithMultiBranchPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/multi-reservation-notices",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  saveReservationOverdueSettingWithMultiBranch: (
    payload: SaveReservationOverdueSettingWithMultiBranchPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/overdue-multi-branch",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  saveReservationEndLunchTimeSettingWithMultiBranch: (
    payload: SaveReservationEndLunchTimeSettingWithMultiBranchPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/reservation-end-lunch-time-setting-with-multi-branch",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  saveReservationBeforeTimeSettingWithMultiBranch: (
    payload: SaveReservationBeforeTimeSettingWithMultiBranchPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/reservation-before-time-setting-with-multi-branch",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  saveReservationBabySeatSettingWithMultiBranch: (
    payload: SaveReservationBabySeatSettingWithMultiBranchPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/reservation-baby-seat-setting-with-multi-branch",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  updateReservationWorkingDateSetting: (
    payload: UpdateReservationWorkingDateSettingPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/update-working-date-settings-for-a-branch",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  updateReservationNoticeSetting: (
    payload: UpdateReservationNoticeSettingPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/reservation-notice-setting",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  updateReservationLockingDateSetting: (
    payload: UpdateReservationLockingDateSettingPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/locking-date-setting",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  updateReservationOverdueSetting: (
    payload: UpdateReservationOverdueSettingPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/booking-time-overdue",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  updateReservationBeforeTimeSetting: (
    payload: UpdateReservationBeforeTimeSettingPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/reservation-before-time-setting",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  updateReservationEndLunchTimeSetting: (
    payload: UpdateReservationEndLunchTimeSettingPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/reservation-end-lunch-time-setting",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },

  updateReservationBabySeatSetting: (
    payload: UpdateReservationBabySeatSettingPayload,
  ) => {
    return authAxios.put<AxiosResponseData>(
      "settings/reservation-baby-seat-setting",
      payload.params,
      {
        cancelToken: payload.cancelToken,
      },
    )
  },
}

export default settingApi
